import {
  audioTypes,
  codecs,
  supportedAudioMimeTypesChrome,
  supportedAudioMimeTypesEdge,
  supportedAudioMimeTypesMozilla,
  supportedAudioMimeTypesSafari,
  supportedMimeTypesChrome,
  supportedMimeTypesEdge,
  supportedMimeTypesMozilla,
  supportedMimeTypesSafari,
  videoTypes,
} from "./constants.helpers";
import { EmailTypeEnum } from "views/components/EmailTemplateBuilder/emailTemplateEnum";
import { formatDate, isDateValid } from "common/utility/Utils";
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  formatDistanceToNow,
} from "date-fns";

export function renderFileUrl(url: string) {
  if (!url?.includes("http://") && !url?.includes("https://")) {
    return `${process.env.REACT_APP_BASENAME}${url}`;
  }
  return url.includes("http://") && !url.includes("blob")
    ? url.replace("http://", "https://")
    : url;
}

export function scrollTop() {
  window.scrollTo(0, 0);
}

export const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
};

export function microdimensionsUnique(array: any[]) {
  var a = array.filter((m: any) => !!m);

  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i]._id === a[j]._id) {
        a.splice(j--, 1);
      }
    }
  }

  return a;
}

export function getSpecificTestRouteName(template: any) {
  switch (template) {
    case "CLOSED_QUESTIONS":
      return "qa-test";
    case "OPEN_QUESTIONS":
      return "qa-test";
    case "PRE_INTERVIEW":
      return "preinterview-test";
    case "TYPING_SPEED":
      return "typing-test";
    case "STATEMENT_SCALE":
      return "scale-test";
    case "QUALIFYING_QUESTIONS":
      return "qa-test";
    default:
      return "test";
  }
}

function checkSupportedMimeTypes(types: string[]) {
  const isSupported = MediaRecorder.isTypeSupported;
  var supported: any[] = [];
  for (let i = 0; i < types.length; i++) {
    supported.push({ type: types[i], isSupported: isSupported(types[i]) });
  }
  return supported;
}

function getSupportedMimeType(types: string[]) {
  const isSupported = MediaRecorder.isTypeSupported;
  for (let i = 0; i < types.length; i++) {
    if (isSupported(types[i])) {
      return types[i];
    }
  }
  return "";
}

export function getBrowserSupportedMimeType(
  browser: string,
  audioOnly?: boolean
) {
  var supportedMimeTypes: string[] = [];
  switch (browser) {
    case "chrome":
      supportedMimeTypes = audioOnly
        ? supportedAudioMimeTypesChrome
        : supportedMimeTypesChrome;
      break;
    case "safari":
      supportedMimeTypes = audioOnly
        ? supportedAudioMimeTypesSafari
        : supportedMimeTypesSafari;
      break;
    case "mozilla":
      supportedMimeTypes = audioOnly
        ? supportedAudioMimeTypesMozilla
        : supportedMimeTypesMozilla;
      break;
    case "edge":
    case "edge-chromium":
      supportedMimeTypes = audioOnly
        ? supportedAudioMimeTypesEdge
        : supportedMimeTypesEdge;
      break;
    default:
      supportedMimeTypes = [];
  }
  console.log(
    "supported mime types",
    checkSupportedMimeTypes(supportedMimeTypes)
  );
  return getSupportedMimeType(supportedMimeTypes);
}

// not actively used, only one time, useful to detect all mime types supported by the browser
export function getSupportedMimeTypes() {
  // media: string
  // types: string[]
  // codecs: string[]
  var tempVideoTypes = videoTypes;
  // var tempVideoCodecs = codecs;

  var tempAudioTypes = audioTypes;
  // var tempAudioCodecs = audioCodecs;

  const isSupported = MediaRecorder.isTypeSupported;
  const supportedVideo: string[] = [];
  const supportedAudio: string[] = [];
  const supportedVideoTemp: { type: string; codec: string }[] = [];
  const supportedAudioTemp: { type: string; codec: string }[] = [];
  tempVideoTypes.forEach((type) => {
    const mimeType = `video/${type}`;
    codecs.forEach((codec) =>
      [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
      ].forEach((variation) => {
        if (isSupported(variation)) {
          supportedVideo.push(variation);
          supportedVideoTemp.push({ type, codec });
        }
      })
    );
    if (isSupported(mimeType)) {
      supportedVideo.push(mimeType);
      supportedVideoTemp.push({ type, codec: "" });
    }
  });
  tempAudioTypes.forEach((type) => {
    const mimeType = `audio/${type}`;
    codecs.forEach((codec) =>
      [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
      ].forEach((variation) => {
        if (isSupported(variation)) {
          supportedAudio.push(variation);
          supportedAudioTemp.push({ type, codec });
        }
      })
    );
    if (isSupported(mimeType)) {
      supportedAudio.push(mimeType);
      supportedAudioTemp.push({ type, codec: "" });
    }
  });
  let universalType = "";
  let universalCodec = "";
  let audioCodec = "";
  let videoCodec = "";
  for (let i = 0; i < supportedVideoTemp.length; i++) {
    for (let j = 0; j < supportedAudioTemp.length; j++) {
      if (supportedVideoTemp[i].type === supportedAudioTemp[j].type) {
        universalType = supportedVideoTemp[i].type;
        if (supportedVideoTemp[i].codec === supportedAudioTemp[j].codec) {
          universalCodec = supportedVideoTemp[i].codec;
        } else {
          audioCodec = supportedAudioTemp[j].codec;
          videoCodec = supportedVideoTemp[i].codec;
        }
      }
    }
  }

  return supportedVideo.concat(supportedAudio);
}

export function getHumanizedApplicationState(value: string) {
  switch (value) {
    case "IN_PROGRESS":
      return "In Progress";
    case "COMPLETED":
      return "Completed";
    case "NOT_STARTED":
      return "Not Started";
    case "UNSCORED":
      return "Unscored";
    case "REJECTED":
      return "Rejected";
    case "PASSED":
      return "Passed";
    case "TIMEDOUT":
      return "Timed out";
    case "HIRED":
      return "Hired";
    default:
      return "Not started";
  }
}

export const areArraysDifferent = (a: any[], b: any[]) => {
  return JSON.stringify(a) !== JSON.stringify(b);
};

export const getTestContentsType = (template: string) => {
  switch (template) {
    case "CLOSED_QUESTIONS":
    case "OPEN_QUESTIONS":
    case "PRE_INTERVIEW":
      return "Question";
    case "TYPING_SPEED":
      return "Sentence";
    case "STATEMENT_SCALE":
      return "Statement";
    default:
      return "Question";
  }
};

export const getLightenColor = (
  originalColor: string,
  lightenAmount: number
) => {
  const lighten = (color: string, amount: number) => {
    // Parse the color into RGB components
    const hex = color.replace(/^#/, "");
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Calculate the new color values
    const newR = Math.min(255, r + amount * (255 - r));
    const newG = Math.min(255, g + amount * (255 - g));
    const newB = Math.min(255, b + amount * (255 - b));

    // Convert back to hex
    const newColor = `#${Math.round(newR).toString(16)}${Math.round(
      newG
    ).toString(16)}${Math.round(newB).toString(16)}`;

    return newColor;
  };

  return lighten(originalColor, lightenAmount);
};

export function getHiringStatusColors(value: string) {
  if (!value) return;
  switch (value.toUpperCase()) {
    case "IN_PROGRESS":
      return "bg-status-in-progress text-status-in-progress-text";
    case "COMPLETED":
      return "bg-status-completed text-status-completed-text";
    case "NOT_STARTED":
      return "bg-status-completed text-status-completed-text";
    case "UNSCORED":
      return "bg-status-unscored text-status-unscored-text";
    case "REJECTED":
      return "bg-status-light-red text-status-red";
    case "PASSED":
      return "bg-status-light-green text-status-green";
    case "TIMEDOUT":
      return "bg-status-light-red text-status-red";
    case "HIRED":
      return "bg-status-light-green text-status-green";
    default:
      return "bg-[#E6E6E6] text-[#787878]";
  }
}

export function fillIcoByState(value: string) {
  if (!value) return;
  switch (value.toUpperCase()) {
    case "IN_PROGRESS":
      return "[&_path]:fill-status-in-progress-text";
    case "COMPLETED":
      return "[&_path]:fill-status-completed-text";
    case "NOT_STARTED":
      return "[&_path]:fill-status-not-started-text";
    case "UNSCORED":
      return "[&_path]:fill-status-unscored-text";
    case "REJECTED":
      return "[&_path]:fill-status-red";
    case "PASSED":
      return "[&_path]:fill-status-green";
    case "TIMEDOUT":
      return "[&_path]:fill-status-red";
    case "HIRED":
      return "[&_path]:fill-status-green";
    default:
      return "[&_path]:fill-[#787878]";
  }
}

export function renderStepStateColor(moduleState: string) {
  if (!moduleState) return;
  switch (moduleState.toUpperCase()) {
    case "IN_PROGRESS":
      return "bg-status-in-progress";
    case "COMPLETED":
      return "bg-status-completed";
    case "NOT_STARTED":
      return "bg-status-completed";
    case "UNSCORED":
      return "bg-status-unscored";
    case "REJECTED":
      return "bg-status-light-red";
    case "PASSED":
      return "bg-status-light-green";
    case "TIMEDOUT":
      return "bg-status-light-red";
    case "HIRED":
      return "bg-status-light-green";
    default:
      return "bg-white";
  }
}
export function renderTextStateColor(moduleState: string) {
  if (!moduleState) return;
  switch (moduleState.toUpperCase()) {
    case "IN_PROGRESS":
      return "text-status-in-progress-text";
    case "COMPLETED":
      return "text-status-completed-text";
    case "NOT_STARTED":
      return "text-status-completed-text";
    case "UNSCORED":
      return "text-status-unscored-text";
    case "REJECTED":
      return "text-status-red";
    case "PASSED":
      return "text-status-green";
    case "TIMEDOUT":
      return "text-status-red";
    case "HIRED":
      return "text-status-green";
    default:
      return "bg-white";
  }
}

export const formatApplicationOrigin = (origin: string) => {
  switch (origin) {
    case "IMPORT":
      return "Manually Imported";
    case "MANUAL_APPLICATION":
      return "Manually applied";
    case "INTEGRATION":
      return "Integration";
    default:
      return "";
  }
};

export const formatEmailType = (emailType: string) => {
  switch (emailType) {
    case EmailTypeEnum.APPLICANT_HIRED:
      return "position-hired-email";
    case EmailTypeEnum.APPLICANT_REJECTED:
      return "position-rejected-email";
    case EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION:
      return "position-apply-email";
    case EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION_AUTOMATIC_PROCEED:
      return "position-apply-auto-proceed-email";
    case EmailTypeEnum.APPLICATION_REMINDER_NOT_STARTED:
      return "assessment-not-started-reminder-email";
    case EmailTypeEnum.APPLICATION_REMINDER_NOT_COMPLETED:
      return "continue-assessment-reminder-email";
    case EmailTypeEnum.APPLICATION_STEP_ACCESS:
      return "assessment-access-email";
    case EmailTypeEnum.APPLICATION_STEP_RESTART:
      return "assessment-retry-email";
    case EmailTypeEnum.APPLICATION_STEP_ACCESS_LIVEINTERVIEW:
      return "live-interview-email";
    case EmailTypeEnum.APPLICATION_CREATED_FOR_TEST_SIMULATION:
      return "test-application-email";
    default:
      return "";
  }
};

export const getEmailTypeEnum = (emailName: string) => {
  switch (emailName) {
    case "position-hired-email":
      return EmailTypeEnum.APPLICANT_HIRED;
    case "position-rejected-email":
      return EmailTypeEnum.APPLICANT_REJECTED;
    case "position-apply-email":
      return EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION;
    case "position-apply-auto-proceed-email":
      return EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION_AUTOMATIC_PROCEED;
    case "assessment-not-started-reminder-email":
      return EmailTypeEnum.APPLICATION_REMINDER_NOT_STARTED;
    case "continue-assessment-reminder-email":
      return EmailTypeEnum.APPLICATION_REMINDER_NOT_COMPLETED;
    case "assessment-access-email":
      return EmailTypeEnum.APPLICATION_STEP_ACCESS;
    case "assessment-retry-email":
      return EmailTypeEnum.APPLICATION_STEP_RESTART;
    case "live-interview-email":
      return EmailTypeEnum.APPLICATION_STEP_ACCESS_LIVEINTERVIEW;
    case "test-application-email":
      return EmailTypeEnum.APPLICATION_CREATED_FOR_TEST_SIMULATION;
    default:
      return "";
  }
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function convertMonthsToYearsAndMonths(monthsInput: number) {
  let years = Math.floor(monthsInput / 12);
  let months = monthsInput % 12;
  if (!years && !months) {
    return null;
  }
  let sentence = !years
    ? `${months ? `${months} month${months > 1 ? "s" : ""}` : ""}`
    : `${years} year${years > 1 ? "s" : ""}${
        months ? ` and ${months} month${months > 1 ? "s" : ""}` : ""
      }`;

  return sentence;
}

export function isApplicationDateExpired(date?: string) {
  if (date) {
    const positionExpiredDate = new Date(
      formatDate(date ? date : "", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    );
    const todaysDate = new Date();

    return !isDateValid(todaysDate, positionExpiredDate);
  } else {
    return false;
  }
}

export const formatPriceForBe = (value: string) => {
  if (value) {
    return value.replace(/\./g, "");
  } else {
    return "";
  }
};

export function findQuestionTextEditorField(node: any, flag: string): string[] {
  let texts: string[] = [];

  if (node[flag]) {
    texts.push(node[flag]);
  }
  if (node.children) {
    node.children.forEach((child: any) => {
      texts = texts.concat(findQuestionTextEditorField(child, flag));
    });
  }

  return texts;
}

export function formatCompanyRoute(
  id: string | null | undefined,
  route: string
) {
  return `${id ? `/${id}` : ""}${route}`;
}

export const getTestInfo = (attachedTests: any, testId: string) => {
  return attachedTests.find((test: { id: string }) => test.id === testId);
};

export const formattedPositionFlow = (flow: any, attachedTests: any) => {
  return flow?.map((item: { testId: string }) => {
    if (item.testId) {
      const testInfo = getTestInfo(attachedTests, item.testId);
      if (testInfo) {
        return testInfo;
      }
    } else {
      // Handle case where testId is not provided in flow item
      return item;
    }
  });
};

// format assessment tests and videos response
export const formatModuleFlowTests = (flowArray: any, testsArray: any) => {
  function getNextTestId(index: number): string | undefined {
    const flow = flowArray;
    for (let i = index + 1; i < flow?.length; i++) {
      const item = flow[i];
      if ("testId" in item) {
        return item.testId;
      }
    }
  }

  const findNextIndexTestState = (index: number) => {
    const findNextTestId = getNextTestId(index);
    const findTestObject = findTest(findNextTestId as any);

    return findTestObject?.appliedState &&
      findTestObject?.appliedState !== "NOT_STARTED"
      ? "COMPLETED"
      : "NOT_STARTED";
  };

  const findTest = (testId: string) => {
    if (testId && testsArray) {
      return testsArray?.find((test: { _id: string }) => test._id === testId);
    }
  };

  return flowArray?.length
    ? flowArray?.map((item: any, index: number) =>
        item?.testId
          ? findTest(item?.testId)
          : {
              ...item,
              appliedState: findNextIndexTestState(index),
              template: {
                enum: "VIDEO",
              },
            }
      )
    : testsArray?.length
      ? testsArray
      : [];
};

export const isDecimal = (num: number): boolean => {
  return num !== Math.floor(num);
};

// cv2jdResults format microdimensions

function cv2jdResultsKey(key: string): string {
  return key
    .replace("in_months", "")
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

interface Experience {
  Score: number;
  Explanation: string;
  hide: boolean;
}

export function cv2jdFormatData(data: {
  [key: string]: Experience;
}): { name: string; score: number; description: string; hide?: boolean }[] {
  const formattedArray: {
    name: string;
    score: number;
    description: string;
    scoredByAi?: string;
    hide?: boolean;
  }[] = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formattedArray.push({
        name: cv2jdResultsKey(key),
        score: data[key].Score > 1 ? 1 : data[key].Score,
        description: data[key].Explanation,
        scoredByAi: "ai",
        hide: data[key].hide,
      });
    }
  }
  return formattedArray;
}

export function formatcv2jdScoring(
  data: Experience
): { name: string; value: number }[] {
  const formattedArray: { name: string; value: number }[] = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formattedArray.push({
        name: cv2jdResultsKey(key),
        value: (data[key as keyof Experience] as number) || 0,
      });
    }
  }
  return formattedArray;
}

// since we have two ai statuses for cv, it checks if processing is complete

export function checkCVProcessingAI(
  cvParseStatus: string,
  cv2jdStatus: string
) {
  if (cvParseStatus === "FAILED" || cv2jdStatus === "FAILED") {
    return "FAILED";
  } else if (cvParseStatus === "PROCESSING" || cv2jdStatus === "PROCESSING") {
    return "PROCESSING";
  } else if (cvParseStatus === "COMPLETED" && cv2jdStatus === "COMPLETED") {
    return "COMPLETED";
  }
}

// function to check if any property has a length greater than 0.

export const hasNonEmptyProperty = (item: any): boolean => {
  return item && typeof item === "object"
    ? Object.values(item).some((value: any) => value.length > 0)
    : false;
};

// function to format initial company identity flags

export const formatCompanyIdentityFlags = (data: any) => {
  return {
    color: data?.color,
    linkColor: data?.linkColor ? data?.linkColor : "",
    enableHeaderColor: data?.enableHeaderColor || false,
    enableHeaderLogo: data?.enableHeaderLogo || false,
    headerColor: data?.headerColor ? data.headerColor : "",
    verticalLogo: {
      preview: data?.logo,
      file: null,
    },
    headerLogo: {
      preview: data?.headerLogo,
      file: null,
    },
  };
};

export const formatTestState = (state: string) => {
  switch (state) {
    case "published":
      return "published";
    case "incubation":
      return "incubator";
    case "draft":
      return "drafts";
    case "archived":
      return "archived";
    default:
      return "";
  }
};

export const numberFormatterDecimal = (value: number, numberFixed: number) => {
  return isDecimal(value) ? value.toFixed(numberFixed) : value;
};

// formats time like 1 min ago and similar
export const formatRelativeTime = (timeString: string) => {
  // Get current date and time
  const now = new Date();

  // Parse the time string into hours and minutes
  const [hours, minutes] = timeString.split(":").map(Number);

  // Create a date object with today's date and the specified time
  const specifiedTime = new Date();
  specifiedTime.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

  // Calculate the difference in milliseconds
  const diffInMillis = now.getTime() - specifiedTime.getTime();

  // Otherwise, format the difference using date-fns
  const formattedTimeAgo = formatDistanceToNow(specifiedTime, {
    addSuffix: true,
  });

  // If the difference is less than a minute, return '0 min ago'
  if (diffInMillis < 60000) {
    return "0 min ago";
  } else {
    let relativeTime = formattedTimeAgo
      .replace(/minutes?/g, "min")
      .replace(/hours?/g, "h")
      .replace(/days?/g, "day")
      .replace(/weeks?/g, "week")
      .replace(/months?/g, "month")
      .replace(/years?/g, "year");
    return relativeTime;
  }
};

// format string like lorem-ipsum
export const formatToHyphenSeparated = (str: string): string => {
  return str
    .trim() // Remove leading and trailing whitespace
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^a-z0-9-]/g, ""); // Remove any non-alphanumeric characters except hyphens
};

export const getCEFRScore = (result: number) => {
  if (result <= 100 && result >= 89) {
    return "C2";
  } else if (result < 89 && result >= 75) {
    return "C1";
  } else if (result < 75 && result >= 60) {
    return "B2";
  } else if (result < 60 && result >= 45) {
    return "B1";
  } else if (result < 45 && result >= 35) {
    return "A2";
  } else if (result < 35 && result >= 20) {
    return "A1";
  } else {
    return "Pre A1";
  }
};

export const getCEFRDescription = (score: string, short?: boolean) => {
  if (score === "C2") {
    return !short
      ? "The candidate has mastered an extensive and nuanced vocabulary, including slang, colloquialisms, and technical terms, enabling them to convey subtle meanings and tone with ease. Their fluency is such that they speak effortlessly and at a natural pace, with complete control over expressions and the ability to adapt to different contexts seamlessly. Pronunciation is indistinguishable from that of a native speaker, with a natural rhythm, intonation, and stress patterns that ensure clear and precise communication."
      : "Proficient";
  } else if (score === "C1") {
    return !short
      ? "The candidate has a wide range of vocabulary at their disposal, including idiomatic expressions and some specialized terms, and they rarely struggle to find the right word. Their speech is fluent and coherent, with little to no hesitation, even when discussing complex or unfamiliar subjects. Pronunciation is near-native, with only minor slips or non-native features, making their speech consistently clear and smooth."
      : "Advanced";
  } else if (score === "B2") {
    return !short
      ? "The candidate possesses a broad vocabulary, allowing for more precise expression of ideas and the effective use of synonyms and paraphrasing. They can speak relatively smoothly and spontaneously, though they may still search for words or rephrase when discussing unfamiliar or complex topics. Pronunciation is clear and mostly accurate, with some lapses. While an accent may still be present, it does not obstruct understanding."
      : "Upper-Intermediate";
  } else if (score === "B1") {
    return !score
      ? "The candidate can use a broader range of common words and expressions, particularly in contexts related to work, school, and leisure, though they may still struggle with more complex vocabulary. Their speech is generally connected and coherent on familiar topics, with occasional hesitation. Pronunciation is usually clear, despite a noticeable accent and occasional errors, particularly with unfamiliar words."
      : "Intermediate";
  } else if (score === "A2") {
    return !score
      ? "The candidate has expanded their vocabulary to include common phrases and expressions related to immediate needs or familiar topics. They can handle short, routine exchanges with some hesitation and pauses, but their speech is becoming more fluid. Pronunciation is more consistent, although noticeable errors still occur. Most of the time, they can be understood, but repetition might be necessary in some cases."
      : "Elementary";
  } else if (score === "A1") {
    return !score
      ? "The candidate's vocabulary is limited to basic, everyday words and phrases, often struggling with less common words. Their speech is characterized by short, isolated sentences with long pauses, making communication slow and fragmented. Pronunciation tends to be unclear, with many errors, though patient listeners can generally understand them."
      : "Beginner";
  } else if (score === "Pre A1") {
    return "Pre A1";
  } else {
    return "";
  }
};

export function renderModuleName(name: string) {
  switch (name) {
    case "Verify email":
      return "Entry";
    default:
      return name;
  }
}

// FORMAT DATE TIME FROM NOW (1 min ago...)
export const formatDistanceCustom = (date: any) => {
  const now = new Date();
  const minutes = differenceInMinutes(now, date);
  const hours = differenceInHours(now, date);
  const days = differenceInDays(now, date);
  const months = differenceInMonths(now, date);
  const years = differenceInYears(now, date);

  if (years > 0) {
    return `${years}y ago`;
  }
  if (months > 0) {
    return `${months}M ago`;
  }
  if (days > 0) {
    return `${days}d ago`;
  }
  if (hours > 0) {
    return `${hours}h ago`;
  }
  if (minutes > 0) {
    return `${minutes}m ago`;
  }

  return "just now"; // For less than a minute
};
